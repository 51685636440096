.Contest {
    text-align: center;
    background-color: black;
    min-height: 100vh;
}

.Contest-header {
    background-color: black;
    padding-left: 10px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-size: 18px;
    color: white;
}

.Contest-header-left {
    height: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Contest-header-left-title {
    padding-left: 5px;
}

.Contest-main {
    background-color: black;
    min-height: 200px;
    height: 70vh;
    padding: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Contest-main-buttons-box{
    min-height: 100px;
    height: 10%;
    min-width: 125px;
    width: 50vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.Contest-main-button{
    background-color: white;
    border-radius: 12px;
    border-width: 0px;
    min-height: 30px;
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 0.75vmin);
    color: black;
}